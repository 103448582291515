<template>
    <div class="purchasesPostList">
        <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
            @onRefresh="onRefresh" :isHigehtMax="true" :isNoData="isNoData" :error="error">
            <Post class="postItem"  :post-info="item" v-for="item in list" :key="item.id" />
        </PullRefresh>
    </div>
</template>

<script>
    import Post from "@/components/Post";
    import PullRefresh from "@/components/PullRefresh";
    import {
        queryBuyList
    } from "@/api/mine";
    export default {
        name: "PurchasesPostList",
        components: {
            Post,
            PullRefresh,
        },
        data() {
            return {
                list: [], // 列表
                pageNumber: 1, // 页码
                pageSize: 10, // 条数
                loading: true, // loading
                refreshing: false, // 刷新状态
                finished: false, // 下拉状态
                error: false, // 是否显示错误信息
                isNoData: false, // 暂无数据
            }
        },
        created() {
            this.getList();
        },
        methods: {
            // 查询列表
            async getList(type) {
                let req = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    newsType: "COVER", // 影视-movie 图文-img 视频-video 话题-tag
                    // uid: JSON.parse(getSessionItem("userInfo")).uid,
                }
                try {
                    let res = await this.$Api(queryBuyList, req);
                    this.loading = false;
                    this.refreshing = false;
                    if (res.code === 200) {
                        let list = res.data.list || [];
                        if (type === 'refresh') {
                            this.list = list;
                        } else {
                            this.list = this.list.concat(list);
                        }
                        // this.isNoData = this.comList.length > 0 ? false : true;
                        if (this.list.length <= 0) {
                            this.isNoData = true;
                        }
                        if (!res.data.hasNext) {
                            this.finished = true;
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.loading = false;
                    this.error = true;
                }

            },
            // 上拉加载
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                this.getList();
            },
            // 下拉刷新
            onRefresh() {
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.loading = true;
                this.getList('refresh');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .purchasesPostList {
        height: calc(100% - 92px);
        // overflow-y: auto;

        .postItem {
          padding: 12px 16px 14px 16px;
          margin-bottom: 10px;
        }
    }
</style>
